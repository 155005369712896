export async function long_poll(action, req_id, token) {
    const delay = ms => new Promise(res => setTimeout(res, ms));

    const pollUrl = `https://gitlab-tool-api.dunelm.io/api/status?req_id=${req_id}&action=${action}`;
    const delayms = action === "groups" ? 500 : 3000;

    console.log(`Polling for uuid: ${req_id}`);

    while (true) {
        try {
            const response = await fetch(pollUrl, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data.Status);

            if (data.Status === 'success' || data.Status === 'failed') {
                return data; // Resolve with the final data
            }

            // Wait before polling again
            await delay(delayms);
        } catch (error) {
            console.error("Error during polling:", error);
            throw error; // Reject the promise on error
        }
    }
}

export async function triggerAction(action, projectId, groupId, includeSubGroups, projectName, token) {
    let url;

    if (projectId) {
        url = `https://gitlab-tool-api.dunelm.io/api/${action}?project_id=${projectId}`;
    } else if (groupId) {
        url = `https://gitlab-tool-api.dunelm.io/api/${action}?group_id=${groupId}&get_subgroups=${includeSubGroups}&project_name=${projectName}`;
    }

    try {
        const response = await fetch(url, {
            headers: { Authorization: `Bearer ${token}` }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const uuid = data.uuid;

        return await long_poll(action, uuid, token); // Wait for long polling to complete
    } catch (error) {
        console.error("Error triggering action:", error);
        throw error; // Reject on error
    }
}