import React from "react";

function HomeTab() {
    return (
        <>
            <div>
                <h1>Dunelm GitLab tool</h1>
                <p> This gui can be used to monitor/lookup repo settings and other such functions.
                    Please use the nav bar above to get started.</p>
            </div>
        </>

    )
}

export default HomeTab