import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";

const ResultsModal = (props) => {
    let {show, handleClose, size, results} = props;


    return (<>
            <Modal onClose={handleClose} size={size} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Results
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {results}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ResultsModal