import {useContext} from "react";
import DropDownContext from "../context/DropDownContext";


const DropDown = () => {

    const {dropDown} = useContext(DropDownContext);
    console.log("Current dropdown context value:", {dropDown});
    return (<>{
        dropDown.map(item => (
            <option
                key={item.full_name}
                value={item.id}
                name={item.full_name}>
                {item.full_name}
            </option>)
        )})
    </>)
}
export default DropDown