import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ConfirmModal from "./confirmModal";

const ActionModal = (props) => {
    let {show, handleClose, size, modalTitle, action, body, includeSubGroups, groupId} = props;

    const [showConfirm, setShowConfirm] = useState(false)


    return (
        <>
            <Modal onClose={handleClose} size={size} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`${modalTitle}`}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {body}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={() => {
                        setShowConfirm(true);
                        handleClose()
                    }}>Submit</Button>
                </Modal.Footer>
            </Modal>
            <ConfirmModal
                show={showConfirm}
                handleClose={() => setShowConfirm(false)}
                handleShow={() => setShowConfirm(true)}
                modalTitle={`Confirm ${modalTitle}`}
                groupId={groupId}
                action={action}
                includeSubGroups={includeSubGroups}
                body={(
                    <>
                        <p>Are you sure you want to proceed with {action}?</p>
                        <p>You have selected: {groupId}</p>
                        <p>Include subgroups: {includeSubGroups.toString()}</p>
                    </>)}
            >
            </ConfirmModal>
        </>
    );
};

export default ActionModal;