import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import ActionModal from "./actionModal";
import Form from "react-bootstrap/Form";
import DropDown from "./DropDown";

const ActionButton = ({buttonName, action, type}) => {

    const [show, setShow] = useState(false)

    const [groupId, setGroupId] = useState(6086725)

    const [includeSubGroups, setIncludeSubGroups] = useState(false)

    function handleChange(e) {
        console.log(e.target.value)
        setGroupId(e.target.value);
    }

    function handleIncludeSubGroups(e) {
        console.log(e.target.checked)

        if (e.target.checked) {
            setIncludeSubGroups(e.target.checked);
        } else
            setIncludeSubGroups(e.target.checked)
    }

    const body = (
        <Form>
            <Form.Group>
                <p>This action will {action} all the protected Branch settings for each repositories under
                    the
                    specified group</p>
            </Form.Group>
            <Form.Group>
                <Form.Select aria-label="Default select example" onChange={handleChange}>
                    <DropDown/>
                </Form.Select>
            </Form.Group>
            <Form.Check
                type="switch"
                id={"subgroups"}
                label={"Include Subgroups?"}
                onChange={handleIncludeSubGroups}
            />
        </Form>)

    return (
        <>
            <Button variant={type} onClick={() => setShow(true)}>
                {buttonName}
            </Button>

            <ActionModal
                show={show}
                handleClose={() => setShow(false)}
                handleShow={() => setShow(true)}
                modalTitle={`${buttonName} Group`}
                action={action}
                body={body}
                includeSubGroups={includeSubGroups}
                groupId={groupId}
            >
            </ActionModal>
        </>
    );
}

export default ActionButton