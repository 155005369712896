import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import ConfirmModal from "./confirmModal";


const TableButton = (props) => {
    const {type, buttonName, id, projectName,} = props

    const [confirmShow, setConfirmShow] = useState(false)


    return (
        <>
            <Button variant={type} id={id} onClick={() => {
                setConfirmShow(true);
                console.log(confirmShow)
            }}>
                {buttonName}
            </Button>
            <ConfirmModal
                show={confirmShow}
                handleClose={() => setConfirmShow(false)}
                title={"Confirm"}
                action={buttonName.toLowerCase()}
                projectName={projectName}
                modalTitle={`Confirm ${buttonName}`}
                projectId={id.split('-', 4)[3]}
                size={'lg'}
                body={(
                    <>
                        <p>Are you sure you want to proceed with {buttonName.toLowerCase()}?</p>
                        <p1><b>You have selected project:</b> '{projectName}' <br/>(ID: {id.substring(id.length, 20)})
                        </p1>
                    </>)}
            >
            </ConfirmModal>
        </>

    )
}

export default TableButton