import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import 'bootstrap/dist/css/bootstrap.css';
import HomeTab from "./homeTab";
import CreateTab from "./createTab";
import ActionsTab from "./actionsTab";
// Remove this line if Loading is not used
// import Loading from "./loading";
import TableWithData from "./backupTableTab";
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const Nav = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress } = useMsal(); // Get inProgress state
    const navigate = useNavigate();

    // Redirect to login if not authenticated
    React.useEffect(() => {
        const loginUser = async () => {
            if (!isAuthenticated && inProgress === "none") { // Check if no interaction is in progress
                try {
                    await instance.loginRedirect({
                        scopes: ["openid", "profile", "api://bdaa2592-db0a-45e8-b491-39ae5f149ad1/azuread.scope"], // Include necessary scopes
                    });
                } catch (error) {
                    console.error("Login error:", error);
                }
            }
        };

        loginUser();
    }, [isAuthenticated, instance, inProgress]);

    if (!isAuthenticated) {
        return null; // Or you can redirect to a login page or show a message
    }

    // Logout function
    const handleLogout = async () => {
        if (inProgress === "none") { // Check if no interaction is in progress
            try {
                await instance.logoutRedirect(); // Log out and redirect
            } catch (error) {
                console.error("Logout error:", error);
            }
        }
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2>Change Freeze Tool</h2>
                <button
                    className="btn btn-danger"
                    onClick={handleLogout}
                    style={{ marginLeft: 'auto' }} // Aligns the button to the right
                >
                    Logout
                </button>
            </div>
            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(key) => {
                    console.log(`Navigating to: /${key}`); // Log navigation action
                    navigate(`/${key}`); // Use navigate on tab change
                }}
            >
                <Tab eventKey="home" title="Home">
                    <HomeTab />
                </Tab>
                <Tab eventKey="display-data" title="Display Data">
                    <TableWithData />
                </Tab>
                <Tab eventKey="create" title="Create">
                    <CreateTab />
                </Tab>
                <Tab eventKey="freeze-actions" title="Freeze-actions">
                    <ActionsTab />
                </Tab>
            </Tabs>
        </div>
    );
};

export default Nav;