import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ResultsModal from "./resultsModal";
import { triggerAction } from "../util/api";
import { useMsal } from '@azure/msal-react';

const ConfirmModal = (props) => {
    let { show, handleClose, size, modalTitle, groupId, includeSubGroups, action, projectName, projectId, body } = props;

    const [results, setResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const { instance } = useMsal();

    const handleSubmit = async (action, groupId, includeSubGroups) => {
        setShowResults(true);
        handleClose();
        console.log(`Going to submit as follows: action: ${action}, project_id: ${projectId} group_id: ${groupId}, subgroups?: ${includeSubGroups}`);
        setResults(<p>Action in Progress..</p>);

        try {
            // Get all accounts
            const accounts = instance.getAllAccounts();

            if (accounts.length > 0) {
                // Set the active account
                instance.setActiveAccount(accounts[0]);

                // Acquire token silently
                const tokenResponse = await instance.acquireTokenSilent({
                    scopes: ["api://bdaa2592-db0a-45e8-b491-39ae5f149ad1/azuread.scope"] // Replace with your API's scopes
                });
                const token = tokenResponse.accessToken;

                // Log request headers
                const requestHeaders = {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                };
                console.log("Request Headers:", requestHeaders);

                // Trigger the action with the acquired token
                const response = await triggerAction(action, projectId, groupId, includeSubGroups, projectName, token);
                console.log("RESPONSE:", response);
                // Log response status and headers
                console.log("Response Status:", response.Status);
                console.log("Response Headers:", response.headers); // Log all response headers

                let innerBody;
                let result = JSON.parse(response.result);

                if (action === "create") {
                    if (response.Status === "success") {
                        innerBody = (
                            <Container>
                                <h3>Action {response.Status}</h3>
                                <p>Project ID: {result.id}</p>
                                <p>Project Name: {result.name}</p>
                                <p>Project Group: {result.namespace}</p>
                                <p><a href={result.url}>{result.url}</a></p>
                                <p>{result.full_name}</p>
                            </Container>
                        );
                    } else {
                        innerBody = (
                            <Container>
                                <h3>Action {response.Status}</h3>
                                <p><b>Reason:</b> {result}</p>
                            </Container>
                        );
                    }
                } else {
                    innerBody = (
                        <Container>
                            <h3>Action {response.Status}!</h3>
                            <Row>
                                <Col>
                                    <h3>Successful:</h3>
                                    {result.successful.map(item => (
                                        <li key={item.name}>
                                            <font size={+2}>Project Name: </font>{item.name}<br />
                                            <font size={+2}> Reason:</font> {item.reason}
                                        </li>
                                    ))}
                                </Col>
                                <Col>
                                    <h3>Unsuccessful:</h3>
                                    {result.unsuccessful.map(item => (
                                        <li key={item.name}>
                                            <b>Project Name:</b> {item.name}<br />
                                            <span><b><text style={{ paddingLeft: 20 }}>Reason:</text></b></span>
                                            {item.reason}
                                        </li>
                                    ))}
                                </Col>
                            </Row>
                        </Container>
                    );
                }
                setResults(innerBody);
            } else {
                console.error("No accounts found. User needs to log in.");
                setResults(<p>Please log in to continue.</p>);
            }
        } catch (error) {
            console.error("Error during submission:", error);
            setResults(<p>Error occurred while processing the action.</p>);
        }
    };

    return (
        <>
            <Modal onClose={handleClose} size={size} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${modalTitle}`}</Modal.Title>
                </Modal.Header>

                <Modal.Body>{body}</Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={() => handleSubmit(action, groupId, includeSubGroups)}>Confirm</Button>
                </Modal.Footer>
            </Modal>

            <ResultsModal
                show={showResults}
                handleClose={() => setShowResults(false)}
                handleShow={() => setShowResults(true)}
                results={results}
                size={"lg"}
            />
        </>
    );
};

export default ConfirmModal;