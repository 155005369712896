import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import React, { useState, useEffect } from "react";
import TableButton from "./tableButton";
import { useMsal } from '@azure/msal-react';

const TableWithData = () => {
    const { SearchBar } = Search;
    const { instance } = useMsal();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const columns = [
        {
            dataField: "_projectId",
            hidden: true
        },
        {
            dataField: "projectId",
            text: "Project ID",
            sort: true
        },
        {
            dataField: "projectName",
            text: "Project Name",
            sort: true
        },
        {
            dataField: "dateTime",
            text: "Time of Backup",
            sort: true
        },
        {
            dataField: "protectedBranchName",
            text: "Branch Name",
            sort: true
        },
        {
            dataField: "attributes",
            text: "Attributes"
        },
        {
            dataField: "",
            text: "Actions",
            formatter: (value, row) => {
                let curID = row["projectId"];
                let projectName = row["projectName"];
                return (
                    <>
                        <TableButton
                            type={"success"}
                            buttonName={"Backup"}
                            id={`btn-backup-project-${curID}`}
                            projectName={projectName}
                        />
                        <TableButton
                            type={"danger"}
                            buttonName={"Thaw"}
                            id={`btn-thaw-project-${curID}`}
                            projectName={projectName}
                        />
                        <TableButton
                            type={"primary"}
                            buttonName={"Freeze"}
                            id={`btn-freeze-project-${curID}`}
                            projectName={projectName}
                        />
                    </>
                );
            }
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const accounts = instance.getAllAccounts();

                if (accounts.length > 0) {
                    instance.setActiveAccount(accounts[0]);
                    setIsAuthenticated(true);

                    // Acquire a token for the API
                    const tokenResponse = await instance.acquireTokenSilent({
                        scopes: ["api://bdaa2592-db0a-45e8-b491-39ae5f149ad1/azuread.scope"], // Use your API's scopes here
                    });

                    // Fetch data using the acquired token
                    const response = await fetch("https://gitlab-tool-api.dunelm.io/api/tabledata", {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${tokenResponse.accessToken}` // Include the token in the Authorization header
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const newData = await response.json();
                    setData(newData);
                } else {
                    console.error("No accounts found. User needs to log in.");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [instance]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <div>Please log in to view the data.</div>;
    }

    return (
        <ToolkitProvider
            keyField="projectId"
            data={data}
            columns={columns}
            search
        >
            {
                props => (
                    <div>
                        <h3>Repository Settings Backup</h3>
                        <SearchBar {...props.searchProps} />
                        <hr />
                        <BootstrapTable
                            {...props.baseProps}
                            striped
                            hover
                            condensed
                            pagination={paginationFactory()}
                        />
                    </div>
                )
            }
        </ToolkitProvider>
    );
};

export default TableWithData;