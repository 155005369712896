import React from 'react';
import { useMsal } from '@azure/msal-react';

const Login = () => {
    const { instance } = useMsal();

    const handleLogin = async () => {
        try {
            await instance.loginRedirect({
                scopes: ["openid", "profile", "api://bdaa2592-db0a-45e8-b491-39ae5f149ad1/azuread.scope"], // Include necessary scopes
            });
        } catch (error) {
            console.error("Login error:", error);
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '20%' }}>
            <h2>Change Freeze Tool</h2>
            <button className="btn btn-primary" onClick={handleLogin}>
                Login
            </button>
        </div>
    );
};

export default Login;