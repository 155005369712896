import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import App from "./components/App";
import { BrowserRouter } from 'react-router-dom';

// MSAL configuration
const msalConfig = {
    auth: {
        clientId: "bdaa2592-db0a-45e8-b491-39ae5f149ad1", // Application (client) ID
        authority: "https://login.microsoftonline.com/7289f9c2-a97d-41e3-af8e-34b6f0186406", // Tenant ID
        redirectUri: window.location.origin, // Where to redirect after login
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set to true for IE 11 or Edge
    },
};

// Create an instance of PublicClientApplication
const msalInstance = new PublicClientApplication(msalConfig);

const Root = () => {
    return (
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Root />
    </BrowserRouter>
);

// Performance measurement
reportWebVitals();