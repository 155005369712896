import React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import Nav from './Nav';
import Login from './Login';
import { DropDownProvider } from '../context/DropDownContext'; // Adjust the import path as necessary

const App = () => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <div>
            {isAuthenticated ? (
                <DropDownProvider>
                    <Nav />
                </DropDownProvider>
            ) : (
                <Login />
            )}
        </div>
    );
};

export default App;