import { createContext, useEffect, useState } from "react";
import { long_poll } from "../util/api";
import { useMsal } from "@azure/msal-react";

const DropDownContext = createContext([]);

export function DropDownProvider({ children }) {
    const { instance } = useMsal();
    const [dropDown, setDropDown] = useState([{ id: 0, full_name: "Loading..." }]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const populate = async () => {
            try {
                const accounts = instance.getAllAccounts();
                if (accounts.length > 0) {
                    instance.setActiveAccount(accounts[0]);
                    const tokenResponse = await instance.acquireTokenSilent({
                        scopes: ["api://bdaa2592-db0a-45e8-b491-39ae5f149ad1/azuread.scope"],
                        account: accounts[0]
                    });
                    const token = tokenResponse.accessToken;

                    const response = await fetch("https://gitlab-tool-api.dunelm.io/api/groups", {
                        headers: { Authorization: `Bearer ${token}` }
                    });

                    if (response.ok) {
                        const data = await response.json();
                        const longPollResult = await long_poll("groups", data.uuid, token);
                        const parsedResult = JSON.parse(longPollResult.result);
                        setDropDown(parsedResult);
                    } else {
                        setError("Error fetching data");
                    }
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        populate();
    }, [instance]);

    return (
        <DropDownContext.Provider value={{ dropDown, loading, error }}>
            {children}
        </DropDownContext.Provider>
    );
}

export default DropDownContext;