import React from 'react';
import ActionButton from "./actionButton";

const ActionsTab = ({dropDown}) => {


    return (
        <>
            <h1>Release Freeze Actions</h1>
            <p>Please select one of the actions below.</p>
            <ActionButton
                dropDown={dropDown}
                buttonName={"Freeze"}
                action={"freeze"}
                type={"danger"}
            />
            <ActionButton
                dropDown={dropDown}
                buttonName={"Thaw"}
                action={"thaw"}
                type={"success"}

            />
            <ActionButton
                dropDown={dropDown}
                buttonName={"Backup"}
                action={"backup"}
                type={"primary"}
            />
        </>
    );
}

export default ActionsTab