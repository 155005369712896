import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, {useState} from "react";
import ConfirmModal from "./confirmModal";
import DropDown from "./DropDown";


const CreateTab = () => {

    const [showConfirm, setShowConfirm] = useState(false)

    const [projectName, setProjectName] = useState("")

    const [group, setGroup] = useState({id: 6086725, full_name:"dunelm"})


    const body = (
        <Form>
            <Form.Group>
                <p>About to create project: {projectName}<br/>Group: {group.full_name}</p>
                <p>Default branch settings will be applied</p>
                <p>Are you sure you wish to continue?</p>
            </Form.Group>
        </Form>)


    const onFormNameChange = (e) => {
        const name = e.target.value
        setProjectName(name)
    }

    function onFormGroupIdChange(e) {
        var selectedIndex = e.target.options.selectedIndex;
        var customAtrribute = e.target.options[selectedIndex].getAttribute('name');
        setGroup({id: e.target.value, full_name: customAtrribute});
        console.log(group)
    }

    return (
        <>
            <Form>
                <h1>Create Project</h1>
                <p> Please enter a repository name and select a group.<br></br> Note. This will apply Dunelm default
                    repository
                    settings.</p>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Project Name</Form.Label>
                    <Form.Control name="full_name" placeholder="Project Name" onChange={onFormNameChange}/>
                    <Form.Text className="text-muted">
                        This cannot contain spaces and should be lower-case.
                        e.g. tf-fastly
                    </Form.Text>
                </Form.Group>
                <Form.Group>
                    <Form.Select aria-label="Default select example" onChange={onFormGroupIdChange}>
                        <DropDown/>
                    </Form.Select>
                </Form.Group>
            </Form>
            <Button variant="primary" type="submit" onClick={() => setShowConfirm(true)}>
                Submit
            </Button>

            <ConfirmModal
                show={showConfirm}
                handleClose={() => setShowConfirm(false)}
                handleShow={() => setShowConfirm(true)}
                groupId={group.id}
                projectName={projectName}
                action={"create"}
                body={body}
                modalTitle={"Confirm Action"}
            >
            </ConfirmModal>
        </>
    )
}

export default CreateTab